import {
  REQUEST_LOGIN,
  CANCEL_LOGIN,
  SUCCESS_LOGIN,
  FAILURE_LOGIN,
  RESET_LOGIN,
  SET_TOKEN,
  SET_USER_LOGIN,
  LOGOUT
} from '../actions/login-actions';

export const loginReducer=(
  state={
    token:false,
    user:{},
    isFetching:false,
    message:false,
    error:false
  },action
)=>{
  const {
    type,
    isFetching,
    message,
    user,
    error,
    token
  } = action
  switch (type) {
    case REQUEST_LOGIN:
      return({...state,isFetching})
    case CANCEL_LOGIN:
      return({...state,isFetching})
    case SUCCESS_LOGIN:
      return({...state,message})
    case FAILURE_LOGIN:
      return({...state,error})
    case SET_TOKEN:
      return({...state,token})
    case SET_USER_LOGIN:
      return({...state,user})
    case LOGOUT:
      return({...state,token})
    case RESET_LOGIN:
      return({...state,isFetching,message,error})

    default:
      return({...state})

  }
}
