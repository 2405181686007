export const GET_GUIDELINES = "GET_GUIDELINES";
export const SET_GUIDELINES = "SET_GUIDELINES";
export const REQUEST_GUIDELINES = "REQUEST_GUIDELINES";
export const CANCEL_GUIDELINES = "CANCEL_GUIDELINES";
export const SUCCESS_GUIDELINES = "SUCCESS_GUIDELINES";
export const FAILURE_GUIDELINES = "FAILURE_GUIDELINES";
export const RESET_GUIDELINES = "RESET_GUIDELINES";

export const get_guidelines=()=>({
  type:GET_GUIDELINES
})

export const set_guidelines=(guidelines)=>({
  type:SET_GUIDELINES,
  guidelines
})

export const request_guidelines=()=>({
  type:REQUEST_GUIDELINES,
  isFetching:true
})

export const cancel_guidelines=()=>({
  type:CANCEL_GUIDELINES,
  isFetching:false
})

export const sucess_guidelines=(message)=>({
  type:SUCCESS_GUIDELINES,
  message
})

export const failure_guidelines=(error)=>({
  type:FAILURE_GUIDELINES,
  error
})

export const reset_guidelines=()=>({
  type:RESET_GUIDELINES,
  isFetching:false,
  error:false,
  message:false
})
