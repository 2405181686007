import React,{Component} from 'react';
import {
  Button,
  Row,
  Card,
  CardFooter,
  UncontrolledAlert as UAlert,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {LOCAL_MESSAGE_TIMEOUT,LOCAL_MESSAGES} from '../../constants';

export default class LoginForm extends Component{
  constructor(props){
    super(props)
    this.state={
      pass:'',
      user:'',
      localMessage:false
    }
  }
  componentDidMount=()=>{
    localStorage.clear()
  }
  getValue=(e)=>{
    const val = e.target.value
    const name = e.target.name
    this.setState({
      [name]:val
    })
  }
  setLocalMessage=(message)=>{
    this.setState({
      localMessage:message
    })
    setTimeout(()=>{
      this.setState({
        localMessage:false
      })
    },LOCAL_MESSAGE_TIMEOUT)
  }
  startSend=(e)=>{
    e.preventDefault()
    const {user,pass} = this.state
    if(pass===''){
      this.setLocalMessage(LOCAL_MESSAGES.nopassword)
      return false
    }
    const loginData = {
      email:user,
      password:pass
    }
    this.props.startLogin(loginData)

  }
  render(){
    const {isFetching,message,error} = this.props
    const {localMessage} = this.state
    return(
      <Card className="text-left border-0" body>
        <Row className="mx-0">
          <h2 className="title_font">
            Bienvenido

          </h2>
        </Row>
        <Row className="mx-0">
          <p className="text-muted">
          Accede a tu cuenta para ver el contenido
          </p>
        </Row>
          {isFetching
            ? <div className="d-flex justify-content-center">
              <FontAwesomeIcon spin icon="spinner"/>
            </div>
            :  <Form onSubmit={this.startSend} id="login_form">
                <FormGroup>
                <Label for="exampleEmail">Email</Label>
                <Input
                  type="email"
                  onChange={this.getValue}
                  name="user"
                  required
                  id="exampleEmail"
                  placeholder="Usuario/correo"/>
              </FormGroup>
              <FormGroup>
                <Row className="justify-content-between mx-1">
                  <Label for="examplePassword">Password</Label>
                  <small className="text-muted">
                  <a href="mailto:soporte@n12.mx">¿olvidaste tu password?</a>
                  </small>
                </Row>
                <Input type="password"
                  onChange={this.getValue}
                  name="pass"
                  id="examplePassword"
                  placeholder="********" />
              </FormGroup>
              <FormGroup>
                <Button block color="primary" form="login_form">
                  Login
                </Button>
              </FormGroup>
              </Form>
          }
        <CardFooter>
          {localMessage
            ? <UAlert>
              {localMessage}
            </UAlert>
            : <small className="text-muted">
              Para generar un usuario nuevo contacte al administrador
            </small>
          }
          {message
            ? <UAlert>
              {message}
            </UAlert>
            : null
          }
          {error
            ? <UAlert color="danger">
              {error}
            </UAlert>
            : null
          }

        </CardFooter>
      </Card>
    )
  }
}
