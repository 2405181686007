import React,{useState,useEffect} from 'react';
import {Nav,NavItem,NavLink,Collapse} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function CollapseMenu(props){
  const [collapsed,setCollapse] = useState(false)
  const {submenu,title,setCurrSect,info,download,parentCollapsed,currSect} = props
  useEffect(()=>{
    if(!parentCollapsed){
      setCollapse(false)
    }
  },[parentCollapsed])
  const toggle =()=>{
    setCollapse(!collapsed)
  }
  const setInfo=()=>{
    if(info){
      setCurrSect({title,info,download})
    } else {
      toggle()
    }

  }
  return(
    <div className="ml-3">
      <NavItem className="item_brand" active={currSect.title===title}>
        <NavLink
          // onClick={()=>setCurrSect(el)}
          onClick={setInfo}
          id={title}
         className="text-light guidelines_link" href="#">
          {submenu?<FontAwesomeIcon icon={collapsed?'minus-circle':'plus-circle'}/>:null}
          &nbsp;{title}
          {parentCollapsed?null:<hr className="border-light mt-1 mb-0"/>}
        </NavLink>
      </NavItem>

        {submenu
          ?
          <Collapse isOpen={collapsed}>
            <Nav vertical>
              {submenu.map(({...rest},o)=><CollapseMenu
                setCurrSect={setCurrSect}
                currSect={currSect}
                parentCollapsed={collapsed}
                {...rest} key={o} />)}
            </Nav>
          </Collapse>
          :null}

    </div>

  )
}

export default CollapseMenu
