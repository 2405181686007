import React,{useState} from 'react';
import {Button,Container,Row,Col,Nav} from 'reactstrap';
import {motion} from 'framer-motion'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CollapseMenu} from '../../elements/brand-sections';
import {LoginFormCont} from '../../../containers'
import logo from '../../../images/logo/logo.svg';



function PrivateSect({token,user,logOut,guidelines:{data=[]}}){
  const [currSect,setCurrSect] = useState(data[0])
  return (
      <Container className="px-0" fluid>
        <Row className="text-center">
          <Col className="bg_login d-flex flex-column sticky-top" xs={3}>

            {token
              ? <>
                <Row className="align-items-center">
                  <Nav vertical className="p-3 nav_guidelines text-left">
                    {/* {data.length?data.map((el,i)=>
                      <div className="ml-5" key={i}>
                        <NavItem key={i} className="title_font">
                          <NavLink
                            // onClick={()=>setCurrSect(el)}
                            id={el.title}
                           className="text-light guidelines_link" href="#">
                            {el.title}
                          </NavLink>
                        </NavItem>
                        <UncontrolledCollapse className="ml-3" toggler={el.title}>
                          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti a dolorem, soluta exercitationem eaque nobis recusandae laboriosam, cumque hic animi!
                        </UncontrolledCollapse>
                      </div>
                    ):null} */}
                    {data.length
                      ?data.map(({...rest},i)=><CollapseMenu
                         setCurrSect={setCurrSect}
                         currSect={currSect}
                         {...rest}
                         key={i} i={i}/>)
                      :null
                    }
                  </Nav>
                </Row>
                <Row className="mt-auto justify-content-center mb-1">
                    <Button className="mb-1" onClick={logOut}>
                      Salir <FontAwesomeIcon icon="sign-out-alt"/>
                    </Button>
                </Row>
              </>
              : <Row className="flex-column justify-content-center align-items-center h-75">
                <img className="img-fluid p-5" src={logo} alt=""/>

              </Row>
            }
          </Col>
          <Col className="d-flex flex-column justify-content-center align-content-center">
            <Row className="justify-content-center">
              <Col className="text-center" xs={token?12:8}>
                {token
                  ? <motion.div
                    initial={{opacity:0,y:'3vh'}}
                    animate={{opacity:1,y:0}}>
                    <Row>
                      <Col>
                        <h3 className="title_font text-center">
                          {currSect.title}
                        </h3>
                      </Col>
                    </Row>
                    <Row>
                      <object data={currSect.info}
                      type="application/pdf"
                      width="100%"
                      height="600"
                      >
                        No se pudo cargar la imagen
                      </object>
                    </Row>
                    </motion.div>
                  : <LoginFormCont/>
                }
              </Col>
            </Row>
            {currSect.download
              ?<Row className="mt-3">
                <Col>
                 <a target="_blank"
                   rel="noopener noreferrer"
                   download={`${currSect.title}.docx`}
                   href={currSect.download}>Descargar&nbsp;
                   <FontAwesomeIcon icon="download"/>
                 </a>
                </Col>
              </Row>

              :null
            }
          </Col>
        </Row>

      </Container>
  )
}

export default PrivateSect
