export const START_LOGIN = "START_LOGIN";
export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const CANCEL_LOGIN = "CANCEL_LOGIN";
export const SUCCESS_LOGIN = "SUCCESS_LOGIN";
export const FAILURE_LOGIN = "FAILURE_LOGIN";
export const RESET_LOGIN = "RESET_LOGIN";
export const SET_USER_LOGIN = "SET_USER_LOGIN";
export const SET_TOKEN = "SET_TOKEN";
export const LOGOUT = 'LOGOUT';

export const start_login=(loginData)=>({
  type:START_LOGIN,
  loginData
})
export const request_login=()=>({
  type:REQUEST_LOGIN,
  isFetching:true
})
export const cancel_login=()=>({
  type:CANCEL_LOGIN,
  isFetching:false
})
export const sucess_login=(message)=>({
  type:SUCCESS_LOGIN,
  message
})

export const failure_login=(error)=>({
  type:FAILURE_LOGIN,
  error
})
export const logout=()=>({
  type:LOGOUT,
  token:false
})
export const set_token=(token)=>({
  type:SET_TOKEN,
  token
})

export const set_user_login=(user)=>({
  type:SET_USER_LOGIN,
  user
})

export const reset_login=()=>({
  type:RESET_LOGIN,
  isFetching:false,
  error:false,
  message:false
})
