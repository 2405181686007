import {API_URL,TOKEN} from '../constants';
import {setHJson,fetchStatus,getErrors} from '../utils';

export const api = {
  postLogin(loginData){
    let init
    init = setHJson(init,'POST',loginData)
    return fetch(`${API_URL}/auth/login`,init)
    .then(fetchStatus)
    .catch(getErrors)
  },
  getGuidelines(){
    let init
    init = setHJson(init)
    return fetch(`${API_URL}/private/guidelines`,init)
    .then(fetchStatus)
    .catch(getErrors)
  }
}

export const local = {
  setLocalToken(token){
  return localStorage.setItem(TOKEN,token)
  },
  revokeLocalToken(){
  return localStorage.clear()
  }
}
