import React from 'react';
import {Jumbotron,Container,Row,Col} from 'reactstrap';
import {motion} from 'framer-motion';

const Jumbo =()=>{
  return (
    <Jumbotron className="jumbo mb-0 d-flex flex-column justify-content-center align-items-center" fluid>
      <Container>
        <Row className="text-center text-light">
          <Col>
            <motion.h1
              initial={{ opacity: 0, y:'3vh' }}
              animate={{ opacity: 1, y:0 }}
              className="display-3 title_font">
              Brand Guides
            </motion.h1>
            <motion.h2
              initial={{ opacity: 0, y:'3vh' }}
              animate={{ opacity: 1, y:0, transition:{delay:.5} }}
              className="display-5">
              Manuales de uso de marca
            </motion.h2>
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  )
}

export default Jumbo
