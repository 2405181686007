import {call,put,takeLatest} from 'redux-saga/effects';
import * as actions from '../actions/login-actions';
import {api,local} from '../../services';

function* postDataLogin({loginData}){
  yield put(actions.reset_login())
  yield put(actions.request_login())
  try{
    const data = yield call(api.postLogin,loginData)
    if(data.error){
      yield put(actions.failure_login(data.error))
      yield put(actions.cancel_login())
      yield call(local.revokeLocalToken)
    } else {
      yield put(actions.set_token(data.token))
      yield put(actions.set_user_login(data.user))
      yield call(local.setLocalToken,data.token)
      yield put(actions.cancel_login())
    }
  } catch(error){
    yield put(actions.failure_login(error))
  }
}

export function* watchLogin(){
  yield takeLatest(actions.START_LOGIN,postDataLogin)
}
