import React from 'react';
import {connect} from 'react-redux';
import {LoginForm} from '../components/forms';
import {start_login} from '../redux/actions/login-actions';

function LoginFormCont({start_login,...rest}){
  return(
    <LoginForm {...rest} startLogin={start_login}/>
  )
}

const mapActionsToProps = {
  start_login
}

export default connect(({loginReducer})=>({
  ...loginReducer
}),mapActionsToProps)(LoginFormCont)
