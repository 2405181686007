import React from "react";
import {Provider} from 'react-redux'
import {store} from '../redux/store';
import {Layout,SEO} from '../components'
import {PrivateSectCont} from '../containers'
import {Jumbo} from '../components/sections/brand-sections';
import '../style/brand-guides.css';

const BrandGuides=()=>{
  return(
    <Provider store={store()}>
      <Layout>
        <SEO title="Brand Guides"/>
        <Jumbo/>
        <PrivateSectCont/>

      </Layout>
    </Provider>

  )
}

export default BrandGuides
