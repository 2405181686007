import React,{useEffect} from 'react';
import {connect} from 'react-redux';
import {PrivateSect} from '../components/sections/brand-sections'
import {logout,set_token} from '../redux/actions/login-actions';
import {get_guidelines} from '../redux/actions/guidelines-actions';
import {TOKEN} from '../constants';

function PrivateSectCont({logout,get_guidelines,set_token,...rest}){
  useEffect(()=>{
    get_guidelines()
    if(window.localStorage){
      set_token(localStorage.getItem(TOKEN))
    }
    //eslint-disable-next-line
  },[])
  // useEffect(()=>{
  //   createUser()
  // },[])
  // const createUser=async()=>{
  //   const body = {
  //   isAdmin:true,
  //   firstname:'María',
  //   lastname:'Haghenbeck',
  //   password:'AlwaysSunny',
  //   email: 'maria@taninnsalon.com',
  //   status:'Active',
  //   }
  //   const resp = await fetch('http://localhost:3016/api/v1/auth/sign_up',{
  //     mode:'cors',
  //     method:'POST',
  //     headers:new Headers({
  //         "Content-Type":"application/json"
  //     }),
  //     body:JSON.stringify(body)
  //   })
  //     .then(r=>r.json())
  //     .then(r=>console.log('newUser',r))
  //   return resp
  // }
  return(
    <PrivateSect
      logOut={logout}
     {...rest}/>
  )
}

const mapStateToProps = ({loginReducer:{token,user},guidelinesReducer})=>({
  token,
  user,
  ...guidelinesReducer
})
const mapActionsToProps = {
  logout,
  get_guidelines,
  set_token
}

export default connect(mapStateToProps,mapActionsToProps)(PrivateSectCont)
