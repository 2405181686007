import {call,put,takeLatest} from 'redux-saga/effects';
import * as actions from '../actions/guidelines-actions';
import {api} from '../../services';

function* getDataGuidelines(){
  yield put(actions.reset_guidelines())
  yield put(actions.request_guidelines())
  try{
    const data = yield call(api.getGuidelines)
    if(data.error){
      yield put(actions.failure_guidelines(data.error))
      yield put(actions.cancel_guidelines())
    } else {
      yield put(actions.cancel_guidelines())
      // yield put(actions.set_guidelines(data.guidelines))
    }
  } catch(error){
    yield put(actions.failure_guidelines(error))
  }
}

export function* watchGuidelines(){
  yield takeLatest(actions.GET_GUIDELINES,getDataGuidelines)
}
