import {
  REQUEST_GUIDELINES,
  SET_GUIDELINES,
  CANCEL_GUIDELINES,
  SUCCESS_GUIDELINES,
  FAILURE_GUIDELINES,
  RESET_GUIDELINES,
} from '../actions/guidelines-actions';

export const guidelinesReducer=(
  state={
    isFetching:false,
    message:false,
    error:false,
    guidelines:{
      data:[
        {

          title:"Modelo de negocio",
          submenu:[
            {
              title:"Presentación",
              info:"https://tan-inn.s3-us-west-1.amazonaws.com/Modelo+de+Negocio/00_TanInn_Presentacion.pdf"
            },
            {
              title:"Manual de Modelo de negocio",
              info:"https://tan-inn.s3-us-west-1.amazonaws.com/Modelo+de+Negocio/01_TanInn_ModeloNegocio.pdf"
            }
          ]

        },
        {
          title:"Manual de Preapertura",
          info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manual+de+Preapertura/02_TanInn_ManualPreapertura.pdf"
        },
        {
          title:"Manual de Marca",
          info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+de+Marca/03_TanInn_ManualMarca.pdf",
        },
        {
          title:"Manual Arquitectónico",
          submenu:[
            {
              title:"Manual de Arquitectura",
              info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+de+Arquitectura/04_TanInn_ManualArquitectura.pdf"
            },
            {
              title:"Anexo de Compendio",
                        submenu:[
                          {
                          title:"Archivero Oficina",
                          info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+de+Arquitectura/Anexo+de+Compendio+/Archivero+Oficina+%7C+Tan+Inn+.pdf"
                        },
                        {
                          title:"Archivero Tan Inn",
                          info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+de+Arquitectura/Anexo+de+Compendio+/Archivero+%7C+Tan+Inn.pdf"
                      },
                      {
                      title:"Celosías Tan Inn",
                      info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+de+Arquitectura/Anexo+de+Compendio+/Celos%C3%ADas+%7C+Tan+Inn+.pdf"
                    },
                    {
                    title:"Display Marca en Muro",
                    info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+de+Arquitectura/Anexo+de+Compendio+/Display+de+Marca+en+Muro.pdf"
                  },
                  {
                  title:"Escritorio Oficina",
                  info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+de+Arquitectura/Anexo+de+Compendio+/Escritorio+Oficina+%7C+Tan+Inn+.pdf"
                },
                {
                title:"Mueble Bronceadores",
                info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+de+Arquitectura/Anexo+de+Compendio+/Mueble+Bronceadores+.pdf"
              },
              {
              title:"Muebles Bronceadores VIP",
              info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+de+Arquitectura/Anexo+de+Compendio+/Mueble+Bronceadores+VIP+%7C+Tan+Inn+.pdf"
            },
            {
            title:"Mueble Pantalla Tan Inn",
            info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+de+Arquitectura/Anexo+de+Compendio+/Mueble+Pantallas++Tan+Inn+.pdf"
          },
          {
          title:"Mueble Display de Marca",
          info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+de+Arquitectura/Anexo+de+Compendio+/Mueble+display+de+Marca++%7C+Tan+Inn+.pdf"
          },
          {
          title:"Plafon Celosías",
          info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+de+Arquitectura/Anexo+de+Compendio+/Plaf%C3%B3n+Celos%C3%ADa+%7C+Tan+Inn+.pdf"
          },
          {
          title:"Proyecto Muestra",
          info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+de+Arquitectura/Anexo+de+Compendio+/Proyecto+Muestra+%7C+Tan+Inn+.pdf"
          },
          {
          title:"Recepción",
          info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+de+Arquitectura/Anexo+de+Compendio+/Recepci%C3%B3n+%7C++Tan+Inn+.pdf"
          },
          {
          title:"Tocador",
          info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+de+Arquitectura/Anexo+de+Compendio+/Tocador+%7C+Tan+Inn+.pdf"
          },
                        ]

        },

              ]
          },
          {
            title:"Manual Financiero",
            submenu:[
              {
                title:"Manual Financiero",
                info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Financiero/05_TanInn_ManualFinanciero.pdf"
              },
              {title:"Anexos",
              submenu:[
                {title:"Depreciación Equipos",
                  info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Financiero/Anexos/DepreciacionEquipos.pdf"},
                  {title:"Estado Resultados",
                info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Financiero/Anexos/EstadoResultados.pdf"}
              ]

              }
            ]
          },
          {
            title:"Manual de Operación",
            submenu:[
              {title:"Manual de Operación",
                info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+de+Operaciones/06_TanInn_ManualOperacion.pdf"
              },
                {title:"Anexo",
                    submenu:[
                      {title:"Descripción de puestos",
                        info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+de+Operaciones/Anexos/Descr_puestos.pdf"
                      }
                    ]
                   }
            ]
          },
          {
            title:"Manual Legal",
            submenu:[
                  {
                  title:"Manual Legal",
                  info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/07_TanInn_ManualLegal.pdf"
                },
                {
                  title: "Recomendaciones",
                  info: "https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/Recomendaciones.pdf"
                },
                {
                  title:"Formato de COF",
                  download:'https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/downloadable/COF_TanInn.docx',
                  info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/Carta+de+Oferta+de+Franquicia+(COF).pdf"
                },
                {
                  title:"Formato de Contrato de Franquicia",
                  download:'https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/downloadable/Contrato_Franquicia_TanInn.docx',
                  info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/Contrato+de+Franquicia+Tan+Inn-Lima+de+Coco.pdf"
                },
                {
                  title:"Solicitud de franquicia",
                  info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/Solicitud_Franquicia.pdf"
                },
                {
                  title:"Contrato de confidencialidad",
                  info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/Contrato_Confidencialidad.pdf"
                },
                {
                  title:"Recepción de COF",
                  info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/CartaRecepcionCOF.pdf"
                },
                {
                  title:"Contrato de Suminstro exclusivo",
                  info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/Contrato_SuministroExclusivo.pdf"
                },

              {
                  title:"Contratos laborales",
                  submenu:[
                    {
                      title:"Confidencialidad",
                      download:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/contratos/downloadable/Carta+de+confidencialidad.docx",
                      info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/contratos/Carta+de+confidencialidad.pdf"
                    },
                    {
                      title:"Laboral 3 meses",
                      download:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/contratos/downloadable/Contrato+capacitación+laboral+(3+meses).docx",
                      info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/contratos/Contrato+capacitaci%C3%B3n+laboral+(3+meses).docx.pdf"
                    },
                    {
                      title:"Gerente Tan Inn",
                      download:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/contratos/downloadable/Contrato+laboral+Gerente+de+Operaciones.docx",
                      info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/contratos/Contrato+laboral+Gerente+de+Operaciones.docx.pdf"
                    },
                    {
                      title:"Gerente Beauty Room",
                      download:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/contratos/downloadable/Contrato+laboral+Gerente+de+Beauty+Room.docx",
                      info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/contratos/Contrato+laboral+Gerente+de+Beauty+Room.docx.pdf"
                    },
                  ]
              },
              {
                title:"Consentimientos",
                          submenu:[
                                   {
                                  title:"Consentimiento Botox",
                                  info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/Consentimiento_Botox.pdf"
                                },
                                {
                                 title:"Consentimiento Crioterapia",
                                 info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/Consentimiento_Crioterapia.pdf"
                               },
                               {
                                title:"Consentimiento IDC Golden Shower",
                                info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/Consentimiento_IDC_Golden_Facial.pdf"
                              },
                              {
                               title:"Consentimiento Plasma",
                               info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/Consentimiento_Plasma.pdf"
                             },
                             {
                              title:"Consentimiento Relleno Facial",
                              info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/Consentimiento_Relleno_Facial.pdf"
                            },
                            {
                             title:"Consentimiento Venus Legacy ",
                             info:"https://tan-inn.s3-us-west-1.amazonaws.com/Manuales+de+Franquicia/Manual+Legal/Consentimiento_Venus_Legacy.pdf"
                           }
                              ]

          },

                ]
            },
      ]
    }
  },action
)=>{
  const {
    type,
    isFetching,
    message,
    error,
    guidelines
  } = action
  switch (type) {
    case REQUEST_GUIDELINES:
      return({...state,isFetching})
    case CANCEL_GUIDELINES:
      return({...state,isFetching})
    case SUCCESS_GUIDELINES:
      return({...state,message})
    case FAILURE_GUIDELINES:
      return({...state,error})
    case SET_GUIDELINES:
      return({...state,guidelines})
    case RESET_GUIDELINES:
      return({...state,isFetching,message,error})

    default:
      return({...state})

  }
}
