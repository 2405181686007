import {combineReducers} from 'redux';

import {loginReducer} from './login-reducer';
import {guidelinesReducer} from './guidelines-reducer';

const rootReducer =()=>combineReducers({
  loginReducer,
  guidelinesReducer
})

export default rootReducer
